import React, {useState} from 'react'
import styles from '../styles/Header.module.scss'
import burger from '../images/icons/burger.svg'
import xMark from '../images/icons/xMark.svg'
import LOGO from '../images/icons/LOGO.svg'
function Header() {

    let [width, setWidth] = useState(window.innerWidth)
    let [isOpen, setIsOpen] = useState(false)
  return (
    <div className={styles.header}> 
        <div className={styles.header__nav}>
        <div className={styles.header__logo_txt}>
                <img src={LOGO} alt="logo" className={styles.header__logo_img} />
            </div>
            <img src={burger}
                className={styles.header__nav_burger}
                alt='burger icon'
                onClick={() => setIsOpen(!isOpen)}
            />
            {width < 1200 && isOpen ?             
            <div className={styles.header__nav_links_wrapper_open}>
                <img src={xMark}
                    className={styles.header__nav_xMark}
                    alt='burger icon'
                    onClick={() => setIsOpen(false)}
                />
                <a href="#benefits" className={isOpen ? styles.header__nav_link_open : styles.header__nav_link}>Our Benefits</a>
                <a href="#mobile" className={isOpen ? styles.header__nav_link_open : styles.header__nav_link}>Mobile app</a>
                <a href="#services" className={isOpen ? styles.header__nav_link_open : styles.header__nav_link}>Additional services</a>
                <a href="#manuals" className={isOpen ? styles.header__nav_link_open : styles.header__nav_link}>ELD Manuals</a>
                <a href="#aboutCompany" className={isOpen ? styles.header__nav_link_open : styles.header__nav_link}>About company</a>
                <a href="#contact" className={isOpen ? styles.header__nav_link_open : styles.header__nav_link}>Contacts</a>
                <a href="https://portal.empirelogs.us/login" target='_blank' className={isOpen ? styles.header__nav_link_login_open : styles.header__nav_link_login} rel="noreferrer">Login</a>
            </div> :             
            <div className={styles.header__nav_links_wrapper}>
                <a href="#benefits" className={isOpen ? styles.header__nav_link_link : styles.header__nav_link}>Our Benefits</a>
                <a href="#mobile" className={isOpen ? styles.header__nav_link_link : styles.header__nav_link}>Mobile app</a>
                <a href="#services" className={isOpen ? styles.header__nav_link_link : styles.header__nav_link}>Additional services</a>
                <a href="#manuals" className={isOpen ? styles.header__nav_link_link : styles.header__nav_link}>ELD Manuals</a>
                <a href="#aboutCompany" className={isOpen ? styles.header__nav_link_link : styles.header__nav_link}>About company</a>
                <a href="#contact" className={isOpen ? styles.header__nav_link_link : styles.header__nav_link}>Contacts</a>
                <a href="https://portal.empirelogs.us/login" target='_blank' className={isOpen ? styles.header__nav_link_login : styles.header__nav_link_login} rel="noreferrer">Login</a>
            </div>}
        </div>
        <div  className={styles.header__info}>
            <div className={styles.header__info_main}>
                <div className={styles.header__info_motto}>
                Building Trust,<br/>
                Mile by Mile
                </div>
                <div className={styles.header__info_txt}>
                    <span className={styles['header__info_txt--bold']}>Empire Logs:</span> Where Efficiency<br/>
                    Meets Accountability.
                </div>
            </div>
            <a href="#contact" className={styles.header__info_btn}>
                Contact us
            </a>
        </div>
    </div>

  )
}

export default Header