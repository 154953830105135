import React from 'react'
import styles from '../styles/Mobile.module.scss'
import mobile from '../images/mobile.png'
import qr from '../images/qr.png'
import logoApp from '../images/logoApp.png'
import googleMarket from '../images/icons/googleMarket.png'

function MobileApp() {
    

  return (
    <div className={styles.mobile} id={"mobile"}> 
        <div className={styles.mobile__left}>
            <div className={styles.mobile__left_top}>
                <div className={styles.mobile__left_title}>Mobile App for free</div>
                <div className={styles.mobile__left_subtitle}>Just $29/Driver/Month</div>
                <a href='https://play.google.com/store/apps/details?id=com.empireeld.mobile&pli=1' target="_blank" rel="noreferrer"> 
                    <img className={styles.mobile__left_googlePlay}src={googleMarket} alt="google market icon"/>
                </a>
                
            </div>
            <div className={styles.mobile__left_bottom}>
                <img src={logoApp} alt="logoApp" className={styles.mobile__left_img}/>
                <img src={qr} alt="qr code" className={styles.mobile__left_img}/>
            </div>
        </div>
        <img  className={styles.mobile__app} src={mobile} alt="mobile app"/>
    </div>

  )
}

export default MobileApp