import React, { useEffect, useState } from 'react'
import styles from '../styles/Benefits.module.scss'
import benefits1 from '../images/icons/benefits-1.svg'
import benefits2 from '../images/icons/benefits-2.svg'
import benefits3 from '../images/icons/benefits-3.svg'
import benefits4 from '../images/icons/benefits-4.svg'
import benefits5 from '../images/icons/benefits-5.svg'
import benefits6 from '../images/icons/benefits-6.svg'

function Benefits() {
    

  return (
    <div className={styles.benefits} id={"benefits"}> 
        <div className={styles.benefits__text}>
        OUR BENEFITS
        </div>
        <div className={styles.benefits__wrapper}>
           <div className={styles.benefits__cell}>
                <img className={styles.benefits__cell_img} src={benefits1} alt="benefits icon"/>
                <div className={styles.benefits__cell_title}>API Integration</div>
                <div className={styles.benefits__cell_txt}>MacroPoint, Project44</div>
           </div>
           <div className={styles.benefits__cell}>
                <img className={styles.benefits__cell_img} src={benefits2} alt="benefits icon"/>
                <div className={styles.benefits__cell_title}>IFTA Miles</div>
                <div className={styles.benefits__cell_txt}>Calculate your IFTA miles at no additional cost</div>
           </div>
           <div className={styles.benefits__cell}>
                <img className={styles.benefits__cell_img} src={benefits3} alt="benefits icon"/>
                <div className={styles.benefits__cell_title}>24/7 customer service</div>
                <div className={styles.benefits__cell_txt}>English, Russian</div>
           </div>
           <div className={styles.benefits__cell}>
                <img className={styles.benefits__cell_img} src={benefits4} alt="benefits icon"/>
                <div className={styles.benefits__cell_title}>Vehicle Diagnostics</div>
                <div className={styles.benefits__cell_txt}>Reduce vehicle <br/> breakdown by scheduling preventative maintenance</div>
           </div>
           <div className={styles.benefits__cell}>
                <img className={styles.benefits__cell_img} src={benefits5} alt="benefits icon"/>
                <div className={styles.benefits__cell_title}>Automate HOS</div>
                <div className={styles.benefits__cell_txt}>Prevent violations with automatic hours of service calculations & alerts</div>
           </div>
           <div className={styles.benefits__cell}>
                <img className={styles.benefits__cell_img} src={benefits6} alt="benefits icon"/>
                <div className={styles.benefits__cell_title}>Manage/Edit DVIRs</div>
                <div className={styles.benefits__cell_txt}>Ensure vehicle safety with easy-to create/edit electronic driver vehicle inspection reports</div>
           </div>
        </div>
    </div>

  )
}

export default Benefits