import React from 'react'
import styles from '../styles/Manuals.module.scss'
import pdf1 from '../pdf/pdf1.pdf'
import pdf2 from '../pdf/pdf2.pdf'

function Manuals() {
  return (
    <div className={styles.manuals} id={"manuals"}> 
      <div className={styles.manuals__title}>ELD Manuals</div>
      <div className={styles.manuals__wrapper}>
      <div className={styles.manuals__item}>
            <a href={pdf2} download="Driver's_Manual_EMPIRE LOGS_ELD.pdf"  target="_blank" 
                className={styles.manuals__item_link}
                type="application/octet-stream" rel="noreferrer"> 
                <div alt="icon for download" className={styles.manuals__item_icon}></div>
            </a>
            <div className={styles.manuals__item_title}>Download</div>
            <div className={styles.manuals__item_txt}>Driver’s Manual</div>
        </div>
        <div className={styles.manuals__item}>
            <a href={pdf1} download="EMPIRE_LOGS_ELD_DOT_CARD_AND_INSTRUCTIONS.pdf" target="_blank" 
                className={styles.manuals__item_link}
                type="application/octet-stream" rel="noreferrer"> 
                <div  className={styles.manuals__item_icon}></div>
            </a>
            <div className={styles.manuals__item_title}>Download</div>
            <div className={styles.manuals__item_txt}>EMPIRE LOGS ELD Card and Manuals</div>
        </div>
      </div>
      
    </div>

  )
}

export default Manuals