import React from 'react'
import styles from '../styles/Footer.module.scss'
import qr from '../images/qr.png'
import logoApp from '../images/logoApp.png'
import googleMarket from '../images/icons/googleMarketFooter.png'
import phone from '../images/icons/telephone.svg'
import mail from '../images/icons/mail.svg'
import mailbox from '../images/icons/mailbox.svg'
import LOGO from '../images/icons/LOGO.svg'

function Footer() {
    
  return (
    <div className={styles.footer} id="contact"> 
        <div className={styles.footer__info_wrapper}>
            <div className={styles.footer__info}>
                <div className={styles.footer__info_title}>Contact Information</div>
                <div className={styles.footer__info_subtitle}>Just type your contacts and we'll get in touch with you.</div>
                <div>
                    <a href='mailto:support@empirelogs.org' target="_blank" rel="noreferrer" className={styles.footer__info_row}>
                        <img src={mail} alt="email" className={styles.footer__info_img}/>
                        <div className={styles.footer__info_link_text}>support@empirelogs.org</div>
                    </a>
                </div>
                <div >
                    <a href='tel:8334555999' target="_blank" rel="noreferrer" className={styles.footer__info_row}>
                        <img src={phone} alt="phone number" className={styles.footer__info_img}/>
                        <div className={styles.footer__info_link_text}>(833) 455-5999</div>
                    </a>
                </div>
                <div >
                    <a href='https://www.google.com/maps/place/1609+Whisper+Glen+Dr,+Plainfield,+IL+60586,+Сполучені+Штати+Америки/data=!4m2!3m1!1s0x880e8c8114117b37:0x741af23045b3408b?sa=X&ved=2ahUKEwiQ64Cflu6CAxXE6AIHHU_nAzkQ8gF6BAgPEAA' target="_blank" rel="noreferrer" className={styles.footer__info_row}>
                        <img src={mailbox} alt="adress" className={styles.footer__info_img}/>
                        <div className={styles.footer__info_link_text}>1609 Whisper Glen Dr, Plainfield, IL 60586</div>
                    </a>
                </div>
            </div>

            <div className={styles.footer__img_wrapper}>
                <div className={styles.footer__img_top}>
                    <img src={logoApp} alt="logoApp" className={styles.footer__img_logo}/>
                    <img src={qr} alt="qr code" className={styles.footer__img_qr}/>
                </div>
                <div className={styles.footer__img_bot}>
                    <a href='https://play.google.com/store/apps/details?id=com.empireeld.mobile&pli=1' target="_blank" rel="noreferrer"> 
                        <img className={styles.footer__img_googlePlay} src={googleMarket} alt="google market icon"/>
                    </a>
                </div>
            </div>
        </div>
        <div className={styles.footer__nav}>
            <div className={styles.footer__logo_txt}>
                <img src={LOGO} className={styles.footer__logo_svg} alt="logo"/>
            </div>
            <div className={styles.footer__nav_links_wrapper}>
                <a href="#benefits" className={styles.footer__nav_link}>Our Benefits</a>
                <a href="#mobile" className={styles.footer__nav_link}>Mobile app</a>
                <a href="#services" className={styles.footer__nav_link}>Additional services</a>
                <a href="#manuals" className={styles.footer__nav_link}>ELD Manuals</a>
                <a href="#aboutCompany" className={styles.footer__nav_link}>About company</a>
            </div>
        </div>
    </div>

  )
}

export default Footer