import styles from './App.module.scss';
import Header from './components/header';
import Benefits from './components/benefits';
import Solutions from './components/solutions';
import MobileApp from './components/mobileApp'
import Services from './components/services'
import Manuals from './components/manuals'
import Customer from './components/customer'
import AboutCompany from './components/aboutCompany';
import Footer from './components/footer';


function App() {
  return (
    <div className={styles.main} >
      <Header />
      <Benefits/>
      <Solutions/>
      <MobileApp />
      <Services />
      <Manuals />
      <Customer />
      <AboutCompany />
      <Footer />
    </div>
  );
}

export default App;
