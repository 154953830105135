import React, { useState } from 'react'
import styles from '../styles/Services.module.scss'
import services1 from '../images/services1.png'
import services2 from '../images/services2.jpg'
import services3 from '../images/services3.jpg'
function Services() {
    
    let [mainImageSrc, setMainImageSrc] = useState(services1)
    let [width, setWidth] = useState(window.innerWidth)
    if(width > 1250 ) {
        return (
 
            <div className={styles.services} id={"services"}> 
                <div className={styles.services__left}>
                    <div className={styles.services__title}>Additional Services</div>
                    <div className={styles.services__subtitle}>PT30 ELD Device</div>
                    <div className={styles.services__txt}>An Electronic Logging Device designed for hours of service (HOS) in compliance with the Federal Motor Carrier Safety Administration and other regulatory agencies around the world. With BLE, the connection is established right inside the app. The PT30 is simple to install: it requires a single connection to the diagnostic connector port inside the truck. Features: J1939, J1708J1587 and OBD-II compliant interfaces Integrated GPS engine and antenna Bluetooth Low Energy (BLE) wireless interface Large capacity storage for offline operations Customizable behavior to match customer requirements 3-Axis accelerometer for driving behavior analysis.</div>
                    <a href="#contact" className={styles.services__link}>Order now</a>
                </div>
                <div className={styles.services__right}>
                    <img src={mainImageSrc} className={styles.services__mainImg} alt="service icon" />
                    <div className={styles.services__img_Wrapper}>
                        <img src={services1} alt="service icon" 
                            className={styles.services__img} 
                            onClick={() => setMainImageSrc(services1)
                        }/>
                        <img src={services2} alt="service icon" 
                            className={styles.services__img} 
                            onClick={() => setMainImageSrc(services2)
                        }/>
                        <img src={services3} alt="service icon" 
                            onClick={() => setMainImageSrc(services3)}
                            className={styles.services__img}    
                        />
                    </div>
                </div>
            </div>
        
          )
    } else {
        return (
            <div className={styles.services} id={"services"}> 
                <div className={styles.services__left}>
                    <div className={styles.services__title}>Additional Services</div>
                    <div className={styles.services__subtitle}>PT30 ELD Device</div>
                    
                    <div className={styles.services__img_Wrapper}>
                    <img src={mainImageSrc} className={styles.services__mainImg} alt="service icon" />
                        <div className={styles.services__secondary_img}>
                            <img src={services1} alt="service icon" 
                                className={styles.services__img} 
                                onClick={() => setMainImageSrc(services1)
                            }/>
                            <img src={services2} alt="service icon" 
                                className={styles.services__img} 
                                onClick={() => setMainImageSrc(services2)
                            }/>
                            <img src={services3} alt="service icon" 
                                onClick={() => setMainImageSrc(services3)}
                                className={styles.services__img}    
                            />
                        </div>
                        
                    </div>
                </div>
                <div className={styles.services__right}>
                    
                    <div className={styles.services__txt}>An Electronic Logging Device designed for hours of service (HOS) in compliance with the Federal Motor Carrier Safety Administration and other regulatory agencies around the world. With BLE, the connection is established right inside the app. The PT30 is simple to install: it requires a single connection to the diagnostic connector port inside the truck. Features: J1939, J1708J1587 and OBD-II compliant interfaces Integrated GPS engine and antenna Bluetooth Low Energy (BLE) wireless interface Large capacity storage for offline operations Customizable behavior to match customer requirements 3-Axis accelerometer for driving behavior analysis.</div>
                    <a href="#contact" className={styles.services__link}>Order now</a>
                </div>
            </div>
        
          )
    }
  
}

export default Services