import React, { useEffect, useState } from 'react'
import styles from '../styles/Solutions.module.scss'
import graph1 from '../images/graph1.png'
import graph2 from '../images/graph2.png'

function Solutions() {
    

  return (
    <div className={styles.solutions}> 
        <div className={styles.solutions__item}>
            <div className={styles.solutions__item_left}>
                <div className={styles.solutions__item_title}>Automate FMCSA Compliance {window.innerWidth >= 1680 ? <br/> : <></>} with the</div>
                <div className={styles.solutions__item_subtitle}>Best ELD, IFTA Mileage Tracking,{window.innerWidth >= 1680 && window.innerWidth <= 768 ? <br/> : <></>} and DVIR Solutions</div>
                <div className={styles.solutions__item_txt}>Ensure compliance and prevent violations using our user-friendly electronic logbook app and compliance portal, both highly rated for their ease of use and editing capabilities.</div>
            </div>
            <img src={graph1} alt='graph' className={styles.solutions__item_img}/>
        </div>

        <div className={styles.solutions__item}>
            <div className={styles.solutions__item_left}>
                <div className={styles.solutions__item_title}>Expand Your Fleet and Boost {window.innerWidth >= 1680 && window.innerWidth <= 768 ? <br/> : <></>} Profits  with the</div>
                <div className={styles.solutions__item_subtitle}>Revamp Fleet Management with Telematics and <br/> Enhanced Maintenance</div>
                <div className={styles.solutions__item_txt}>Achieve full fleet transparency and efficiency through our advanced 4G/LTE GPS tracking system and user-friendly management portal.</div>
            </div>
            <img src={graph2} alt='graph' className={styles.solutions__item_img}/>
        </div>
    </div>

  )
}

export default Solutions