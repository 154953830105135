import React from 'react'
import styles from '../styles/Customer.module.scss'
import person1 from '../images/person1.jpg'
import person2 from '../images/person2.jpg'
import person3 from '../images/person3.jpg'


function Customer() {
    
  return (
    <div className={styles.customer}> 
        <div className={styles.customer__wrapper_top}>
            <div className={styles.customer__title}>
                Here what our customer have to say
            </div>
            <div className={styles.customer__sub_title}>
                Become part of a community of satisfied drivers,<br/>
                owner-operators, and fleet managers!
            </div>
        </div>

        <div className={styles.customer__wrapper_bot}>
            <div className={styles.customer__card}>
                <div className={styles.customer__card_txt}>Empire Logs made my trucking life so much easier. Their ELD system is user-friendly, and I no longer worry about paper logs. It's a game-changer.</div>
                <div className={styles.customer__card_info}>
                    <img src={person1} alt="person img" className={styles.customer__card_img}/>
                    <div className={styles.customer__card_person_info}>
                        <div className={styles.customer__card_person}>
                        James Johnson
                        </div>
                        <div className={styles.customer__card_job}>
                            Driver
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.customer__card}>
                <div className={styles.customer__card_txt}>I've been using Empire Logs for a while now, and I'm impressed. The ELD device is reliable, and the customer support team is super helpful. Definitely recommend!</div>
                <div className={styles.customer__card_info}>
                    <img src={person2} alt="person img" className={styles.customer__card_img}/>
                    <div className={styles.customer__card_person_info}>
                        <div className={styles.customer__card_person}>
                        Robert Wilson
                        </div>
                        <div className={styles.customer__card_job}>
                            Fleet Owner
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.customer__card}>
                <div className={styles.customer__card_txt}>Empire Logs' ELD saved me time and money. It's easy to install and use. The app keeps me compliant, and I can focus on driving. Great product!</div>
                <div className={styles.customer__card_info}>
                    <img src={person3} alt="person img" className={styles.customer__card_img}/>
                    <div className={styles.customer__card_person_info}>
                        <div className={styles.customer__card_person}>
                            Jonathan Davis
                        </div>
                        <div className={styles.customer__card_job}>
                            Logistics Manager
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  )
}

export default Customer