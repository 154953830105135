import React from 'react'
import styles from '../styles/AboutCompany.module.scss'
import aboutUs from '../images/aboutUs.png'

function AboutCompany() {
    
  return (
    <div className={styles.aboutCompany} id={'aboutCompany'}> 
        <div className={styles.aboutCompany__info}>
            <div className={styles.aboutCompany__info_title}>
             About company
            </div>
            <div className={styles.aboutCompany__info_txt}>
                Empire Logs is more than just an ELD provider; we are a team that understands the life of drivers on the open road. Unlike other solutions in the industry, our software is made with the needs and standards of those who spend their days navigating highways in mind.
                <br />
                <br />
                Empire Logs was born from a deep commitment to improving the lives of truckers and fleet operators. We recognize the challenges you face, from tight schedules to ever-evolving regulations. 
            </div>
        </div>
        <img src={aboutUs} alt="truck img" className={styles.aboutCompany__img}/>
    </div>

  )
}

export default AboutCompany